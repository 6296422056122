import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import MuxVideoPlayer from '~components/MuxVideoPlayer'
import Media from '~components/Media'

const SingleMedia = ({ className, content }) => {
	return (
		<Wrap
			className={className}
			bg={content?.background}
			padding={content?.padding}
		>
			{content?.showVideoControls ? (
				<MuxVideoPlayer
					desktopPlaybackId={content?.media?.desktopVideoMux?.asset?.playbackId}
					mobilePlaybackId={content?.media?.mobileVideoMux?.asset?.playbackId}
					soundToggle={true}
				/>
			) : (
				<Block>
					<Media media={content.media} />
				</Block>
			)}
		</Wrap>
	)
}

const Wrap = styled.div`
	background-color: ${props => props.bg};
	padding: ${props => props.padding ? '115px  71px' : '0'};
	transform: scale(1.00001);
	${mobile}{
		padding: ${props => props.padding ? '125px  20px' : '0'};
	}
`

const Block = styled.div`
	line-height: 0;
`

SingleMedia.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default SingleMedia