import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Media from '~components/Media'

const DoubleMedia = ({ className, content }) => {
	return (
		<Wrap
			className={className}
			bg={content.background}
			stackMob={content.stackOnMobile}
			padding={content.padding}
		>
			<LeftMedia media={content.leftMedia} />
			<RightMedia media={content.rightMedia} />
		</Wrap>
	)
}

const Wrap = styled.div`
	background-color: ${props => props.bg};
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: ${props => props.padding ? '115px  102px' : '0'};
	grid-column-gap: ${props => props.padding ? '148px' : '0'};
	${mobile}{
		grid-template-columns: ${props => props.stackMob ? '1fr' : 'repeat(2, 1fr); '};
		padding: ${props => props.padding ? '20px' : '0'};
		grid-column-gap: ${props => props.padding ? '40px' : '0'};
		grid-row-gap: ${props => props.padding ? '40px' : '0'};
	}
`

const LeftMedia = styled(Media)`
	display: flex;
	align-items: center;
	transform: scale(1.0001);
`
const RightMedia = styled(Media)`
	display: flex;
	align-items: center;
	transform: scale(1.0001);
`

DoubleMedia.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default DoubleMedia