import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import Image from '~components/Image'
import { useState } from 'react'
import Hls from 'hls.js'

const HoverButton = ({ className, project, firstCol, secondCol, thirdCol }) => {
	const [hover, setHover] = useState(false)
	const [mobile, setMobile] = useState(false)
	const videoRef = useRef(null)
	const hlsRef = useRef(null)

	const getMuxHlsUrl = (playbackId) => {
		return `https://stream.mux.com/${playbackId}.m3u8`
	}

	useEffect(() => {
		if (window.innerWidth < 768) {
			setMobile(true)
		}
	}, [])

	useEffect(() => {
		if (project?.thumbnailMuxVideo?.asset?.playbackId && videoRef.current) {
			const video = videoRef.current
			const src = getMuxHlsUrl(project.thumbnailMuxVideo.asset.playbackId)

			if (video.canPlayType('application/vnd.apple.mpegurl')) {
				video.src = src
				video.load()
			} else if (Hls.isSupported()) {
				if (hlsRef.current) {
					hlsRef.current.destroy()
				}
				hlsRef.current = new Hls()
				hlsRef.current.loadSource(src)
				hlsRef.current.attachMedia(video)
			}
		}

		return () => {
			if (hlsRef.current) {
				hlsRef.current.destroy()
				hlsRef.current = null
			}
			if (videoRef.current) {
				videoRef.current.src = ''
			}
		}
	}, [project?.thumbnailMuxVideo?.asset?.playbackId])

	return (
		<ButtonWrap className={className} firstCol={firstCol} secondCol={secondCol} thirdCol={thirdCol}>
			<ProjectLink
				to={resolveLink(project)}
			>
				<Hover
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					{project?.title}
				</Hover>
				{!mobile &&
					<HoverBlock hover={hover}>
						{(project?.thumbnailMuxVideo || project?.thumbnailVideo?.asset?.url || project?.thumbnailVimeoVideo) ?
							<video
								ref={videoRef}
								src={!project?.thumbnailMuxVideo ? (project?.thumbnailVimeoVideo ?? project?.thumbnailVideo?.asset?.url) : ''}
								style={{
									maxWidth: '100%',
									display: 'block'
								}}
								playsInline
								autoPlay
								muted
								loop
							/>
							:
							<HoverImage image={project?.thumbnail} />
						}
					</HoverBlock>
				}
			</ProjectLink>
		</ButtonWrap>
	)
}

const MoreWork = ({ className, projects }) => {

	const firstThird = (Math.ceil(projects?.length / 3))
	const secondThird = firstThird + firstThird

	return (
		<Wrap className={className}>
			<MoreWorkTitle>More work:</MoreWorkTitle>
			<Columns>
				<DesktopCol>
					{projects?.slice(0, firstThird).map((project, i) => (
						<HoverButton
							key={project?.title}
							project={project}
						/>
					))}
				</DesktopCol>
				<DesktopCol>
					{projects?.slice(firstThird, secondThird).map((project, i) => (
						<HoverButton
							key={project?.title}
							project={project}
						/>
					))}
				</DesktopCol>
				<DesktopCol>
					{projects?.slice(secondThird, projects?.length).map((project, i) => (
						<HoverButton
							key={project?.title}
							project={project}
						/>
					))}
				</DesktopCol>
				<TabletCol>
					{projects?.slice(0, Math.ceil(projects?.length / 2)).map((project, i) => (
						<HoverButton
							key={project?.title}
							project={project}
						/>
					))}
				</TabletCol>
				<TabletCol>
					{projects?.slice(Math.ceil(projects?.length / 2), projects?.length).map((project, i) => (
						<HoverButton
							key={project?.title}
							project={project}
						/>
					))}
				</TabletCol>
			</Columns>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: 40px 0 64px;
	${mobile}{
		padding: 31px 0 25px;
	}
`
const MoreWorkTitle = styled.div`
	grid-column: span 12;
	margin-bottom: 26px;
`
const Columns = styled.div`
	grid-column: span 12;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	position: relative;
	grid-column-gap: 30px;
	${tablet}{
		grid-template-columns: repeat(2, 1fr);
	}
	${mobile}{
		grid-column-gap: 20px;
	}
`
const ButtonWrap = styled.div`
	grid-column: ${props => props.firstCol ? '1/2' : props.secondCol ? '2/3' : '3/4'};
`
const ProjectLink = styled(Link)`
	${mobile}{
		margin-bottom: 7px;
		display: block;
	}
`
const Hover = styled.div`
	:hover{
		text-decoration: underline;
	}
`
const HoverBlock = styled.div`
	position: absolute;
	width: 19%;
	top: 0;
	transform: translateX(75%);
	pointer-events: none;
	opacity: ${props => props.hover ? '1' : '0'};
	z-index: 1;
	${tablet}{
		width: 30%;
		transform: translateX(66.5%);
	}
	${mobile}{
		display: none;
	}
`
const HoverImage = styled(Image)`

`

const DesktopCol = styled.div`
	${tablet}{
		display: none;
	}
`
const TabletCol = styled.div`
	display: none;
	${tablet}{
		display: block;
	}
`
MoreWork.propTypes = {
	className: PropTypes.string,
	projects: PropTypes.array,
}

HoverButton.propTypes = {
	className: PropTypes.string,
	project: PropTypes.object,
}

export default MoreWork